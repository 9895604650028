import * as React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout';
//import SEO from "../components/seo"

export const query = graphql`
  query {
    allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "teamPage"}}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
          }
        }
      }
    }
  }
}`

const TeamPage = (props) => {
    //const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter;
    return (
        <Layout pageName = "teamPage">
            <div className="container-fluid" id="main">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="left-index">
                            <h2><strong>Meet the Team</strong></h2>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="right-index">
                            <p>Blablah</p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default TeamPage
